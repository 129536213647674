import { Controller } from '@hotwired/stimulus';

import Infinite from '../javascript/waypoints-infinite.ts';

export default class extends Controller {
  connect() {
    new Infinite({
      element: this.element as HTMLElement,
      more: '.next-link',
      items: '.feed-element',
    });
  }
}
